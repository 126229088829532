const userRoutes = [
    {
        path: '/user',
        name: 'user',
        children: [
            {
                path: 'create',
                name: 'user-create',
                component: () => import('@/views/CreateAccountFormView.vue')
            },
            {
                path: 'created',
                name: 'user-created',
                component: () => import('@/components/organisms/UserCreated/UserCreated.vue')
            },
            {
                path: 'resend-validation',
                name: 'user-resend-validation',
                component: () =>
                    import('@/components/organisms/ResendValidation/ResendValidation.vue')
            },
            {
                path: 'is-validated',
                name: 'user-is-validated',
                component: () => import('@/components/organisms/IsValidated/IsValidated.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue'),
        meta: { title: 'Login' }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPasswordView.vue'),
        meta: { title: 'Recuperar Senha' },
        children: [
            {
                path: 'recover',
                name: 'recover-password',
                component: () => import('@/components/organisms/RecoverPassword/RecoverPassword.vue')
            },
            {
                path: 'feedback/:status',
                name: 'recover-feedback-status',
                component: () => import('@/components/organisms/FeedbackRecoverPassword/FeedbackRecoverPassword.vue')
            },
        ]
    },
    {
        path: '/forgot-password/reset',
        name: 'reset-password',
        component: () => import('@/components/organisms/ResetPassword/ResetPassword.vue')
    }
]

export default userRoutes
