import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import HomeView from '@/views/HomeView/HomeView.vue'
import authRoutes from './modules/auth'
import createAppRoutes from './modules/createApp'
import appsRoutes from './modules/apps'
import userRoutes from './modules/user'
import axios from 'axios'
import checkPublished from '@/middlewares/checkPublished'
import checkPreviousRoute from '@/middlewares/checkPreviousRoute'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            meta: {
                title: 'Home',
                internalValidation: true
            }
        },
        ...createAppRoutes,
        ...userRoutes,
        ...authRoutes,
        ...appsRoutes,
        {
            path: '/appmax-website',
            name: 'appmax-website',
            beforeEnter(to, from, next) {
                window.open('https://appmax.com.br', '_blank')

                next({ name: 'home' })
            }
        },
        {
            path: '/feedback/:status',
            name: 'app-feedback',
            component: () => import('@organisms/AppSuccess/AppSuccess.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: () => import('@/views/NotFoundView.vue')
        },
        {
            path: '/app/create',
            name: 'create-app',
            component: () => import('@/views/CreateAppView.vue'),
            meta: {
                requiresAuth: true
            },
            beforeEnter: checkPreviousRoute,
            children: [
              {
                path: 'about-app',
                name: 'create-app_about-app',
                component: () => import('@/components/molecules/AboutApp/AboutApp.vue'),
              },
              {
                path: 'images-app',
                name: 'create-app_images-app',
                component: () => import('@/components/molecules/ImagesApp/ImagesApp.vue')
              },
              {
                path: 'config-app',
                name: 'create-app_config-app',
                component: () => import('@/components/molecules/ConfigApp/ConfigApp.vue')
              }
            ]
        },
        {
            path: '/app/:id/edit',
            name: 'edit-app',
            component: () => import('@/views/EditAppView.vue'),
            meta: {
                internalValidation: true,
            },
            beforeEnter: checkPublished,
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()

    document.title = to.meta.title ? to.meta.title : 'Appmax Store'

    if (to.meta.requiresAuth) {
        await axios
            .get(
                `${
                    import.meta.env.VITE_APP_APPMAX_API_APPBOX_URL
                }/validate-token`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem(
                            'user-token'
                        )}`
                    }
                }
            )
            .then(() => {
                return next()
            })
            .catch(() => {
                authStore.setStatusAuthentication(false)
                localStorage.removeItem('user-token')
                return next({ name: 'login' })
            })
    } else {
        next()
    }
})

export default router
