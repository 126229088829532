<template>
  <div
    class="am_feedback">
    <div
      class="am_feedback--feedback">
      <slot
        name="feedback" />
    </div>
    <AmTypography
      class="am_feedback--title"
      :text="title"
      :typographyStyle="amTypographyClasses.title"
      weight="medium"
    />
    <AmTypography
      v-if="subtitle"
      class="am_feedback--subtitle"
      :text="subtitle"
      :typographyStyle="amTypographyClasses.subtitle"
    />
    <AmTypography
      class="am_feedback--description"
      :text="description"
      :typographyStyle="amTypographyClasses.description"
    />
    <AmDeveloperCard
      class="am_feedback--icon"
    />
  </div>
</template>

<script>
import { AmTypography } from '@appmax_npm/ds-appmax-v3'
import { computed, inject } from 'vue'
import { AmDeveloperCard } from '@icons'

export default {
  name: 'AmFeedback',
  components: {
    AmTypography,
    AmDeveloperCard
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  setup() {
    const mq = inject('mq')

    const amTypographyClasses = computed(() => {
      const typographyClasses = {
        mobile: {
          title: 'l4',
          subtitle: 'l4',
          description: 'l6'
        },
        tablet: {
          title: 'l1',
          subtitle: 'l1',
          description: 'l3'
        },
        desktop: {
          title: 'l1',
          subtitle: 'l1',
          description: 'l3'
        }
      }

      return typographyClasses[mq.current]
    })
    return {
      amTypographyClasses,
      mq
    }
  }
}
</script>

<style lang="scss" scoped>
.am_feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 10px 53px;

  &--feedback {
    margin: 0 0 16px 0;
  }

  &--title {
    margin: 0 0 6px 0;
  }

  &--description {
    margin: 17px 0 0 0;
    max-width: 468px;
  }

  &--icon {
    margin: 75px 0 50px 0;
  }

  &--mobile {
    padding: 0 50px 0 50px;
  }
}
</style>
