<template>
  <div>
    <div
      ref="recaptchaContainer"></div>
    <div
      v-if="error"
      style="color: red">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'ReCaptcha',
  props: {
    siteKey: {
      type: String,
      required: true
    },
    recaptchaId: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: 'Please verify that you are not a robot.'
    }
  },
  data() {
    return {
      recaptchaWidgetId: null,
      error: null
    }
  },
  mounted() {
    this.loadReCaptcha().then(() => {
      this.renderReCaptcha()
    })
  },
  methods: {
    loadReCaptcha() {
      return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src =
          'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit'
        script.async = true
        script.defer = true
        window.onRecaptchaLoad = resolve
        document.head.appendChild(script)
      })
    },
    renderReCaptcha() {
      this.recaptchaWidgetId = window.grecaptcha.render(this.$refs.recaptchaContainer, {
        sitekey: this.siteKey,
        callback: this.onReCaptchaVerified,
        'expired-callback': this.onReCaptchaExpired
      })
    },
    onReCaptchaVerified(response) {
      this.$emit('captcha-verified', response)
      this.error = null
    },
    onReCaptchaExpired() {
      this.error = this.errorMessage
    }
  }
}
</script>
