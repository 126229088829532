import { defineStore } from 'pinia'
import { useHttpStore } from './http'

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            isAuthenticated: false,
            token: '',
            verifiedEmail: '',
            recoverPassword: {
                email: '',
                attempts: 0,
                maxAttempts: 3
            }
        }
    },
    getters: {
        hasToken: () => localStorage.getItem('user-token') !== null,
        getToken: state => state.token,
        getAuthenticatedStatus: state => state.isAuthenticated,
    },
    actions: {
        setStatusAuthentication(status) {
            this.isAuthenticated = status
        },
        setToken(token) {
            this.token = token
        },
        async validateToken() {
            const httpStore = useHttpStore()
            let isValid = false

            await httpStore.apiOAuth
                .get(`/validate-token`)
                .then(() => {
                    this.setStatusAuthentication(true)
                    isValid = true
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.setStatusAuthentication(false)
                    }

                    isValid = false
                })

            return isValid
        },
        setVerifiedEmail(email) {
            this.recoverPassword.email = email
        }
    },
    persist: true
})
