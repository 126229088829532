<template>
  <section class="section-container">
    <div class="section-container__light-focus"></div>
    <div class="section-container__content">
      <h1 class="section-container__title">
        <img
          src="/src/assets/homeView/bug.svg"
          class="section-container__icon"
        />
        <span>{{ $t('home.helloDevTitle') }}</span>
      </h1>
      <img
        :src="editorImagePath"
        class="section-container__editor-image" />
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const isDesktop = useMediaQuery('(min-width: 550px)');

const editorImagePath = computed(() => {
  const languageSuffix = locale.value === 'en-US' ? '_en' : '';
  return isDesktop.value
    ? `./images/home-view/editor_desktop${languageSuffix}.webp`
    : `./images/home-view/editor_mobile${languageSuffix}.webp`;
});
</script>

<style scoped lang="scss">
.section-container {
  position: relative;
  max-width: 1134px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$mediaMaxWidth768} {
    height: auto;
  }

  &__light-focus {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 118%;
    height: 65%;
    filter: blur(80px);
    z-index: 0;
    background: radial-gradient(
      ellipse at center,
      rgba(139, 120, 255, 0.39) 0%,
      rgba(99, 73, 255, 0.37) 50%,
      transparent 100%
    );
    @media #{$mediaMaxWidth768} {
      display: none;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 106px;
    @media #{$mediaMaxWidth768} {
      padding-top: 32px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: $firaCode;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $white;
    margin-bottom: 16px;
    @media #{$mediaMaxWidth768} {
      font-size: 24px;
      margin-bottom: 32px;
    }

    img.section-container__icon {
      width: 48px;
      margin-right: 16px;
      @media #{$mediaMaxWidth768} {
        width: 28px;
        margin-right: 9.5px;
      }
    }
  }

  &__editor-image {
    height: auto;
  }
}

</style>
