<template>
  <div>
    <AmFeedback
      :title="$t('resendValidation.title')"
      :description="$t('resendValidation.description')"
      icon="checked"
    >
      <template
        #feedback>
        <AmIcon
          :class="['resendValidation--icon', ...iconClasses]"
          name="replay"
          color="#fff" />
      </template>
    </AmFeedback>
  </div>
</template>

<script>
import { AmIcon } from '@appmax_npm/ds-appmax-v3'
import AmFeedback from '../../molecules/AmFeedback/AmFeedback.vue'

export default {
  name: 'ResendValidation',
  components: { AmIcon, AmFeedback },
  setup() {
    const iconClasses = ['bg-appmaxPurple rounded-xl p-1 flex items-center justify-center']
    return {
      iconClasses
    }
  }
}
</script>
