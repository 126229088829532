import _ from 'lodash'

const authRoutes = [
    {
        path: '/first-access',
        name: 'first-access',
        component: () => import('@/views/FirstAccessView/FirstAccessView.vue'),
    },
    {
        path: '/success-app',
        name: 'success-app',
        component: () => import('@/views/AppSuccessView.vue')
    },
    {
        path: '/status',
        name: 'status',
        component: () => import('@/views/StatusView.vue')
    },
    {
        path: '/confirmation-to-edit',
        name: 'confirmation-to-edit',
        component: () => import('@/views/ConfirmationToEdit/ConfirmationToEditView.vue')
    },
]

authRoutes.forEach((to, from, routes) => {
    _.forEach(routes, (route) => {
        route.meta = {
            requiresAuth: true
        }
    });
})

export default authRoutes
