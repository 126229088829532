<template>
  <div :class="`create-account-form create-account-form-view_${mq.current}`">
    <div class="develop-your-app">
      <h1
        id="title"
        class="title-create-account">
        <span>{{ $t('login.developYourAppComponent.title') }}</span>
        <span class="color-emphasis">{{ $t('login.developYourAppComponent.emphasis') }}</span>
        <span>{{ $t('login.developYourAppComponent.nextTitle') }}</span>
      </h1>
    </div>
    <CreateAccountForm />
  </div>
</template>

<script setup>
import CreateAccountForm from '@/components/organisms/CreateAccountForm/CreateAccountForm.vue'
import { isDarkTheme } from '@/services/theme-state.js';
import { computed, inject, onBeforeUnmount, onMounted, onUnmounted } from 'vue'
import { useReturnBarStore } from '@/stores/returnBar'
import { useI18n } from 'vue-i18n'

const mq = inject('mq')
const { t } = useI18n()
const returnBarStore = useReturnBarStore()
const message = computed(() => t('home.returnBar.title'))

onMounted(() => {
  returnBarStore.setTitle(message)
  returnBarStore.setPath('appmax-website')
  document.body.classList.add('dark-theme');
  isDarkTheme.value = true;
})

onBeforeUnmount(() => {
  returnBarStore.resetAll()
})

onUnmounted(() => {
  document.body.classList.remove('dark-theme');
  isDarkTheme.value = false;
})
</script>

<style lang="scss" scoped>
.create-account-form-view_desktop {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .develop-your-app {
    display: flex;
    align-items: center;

    #title {
      text-align: start;
    }
  }
}

.create-account-form-view_mobile,
.create-account-form-view_tablet {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 24px;
  margin: 10px 30px 20px 30px;

  @media (max-width: 470px) {
  display: flex;
  flex-direction: column;
}

  #title {
    text-align: center;
  }
}

.title-create-account {
    font-family: $secondaryFont;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $white;
    padding: 185px 115px 242px 0;
    @media #{$mediaMaxWidth768}, #{$mediaMaxWidth995} {
      font-size: 16px;
      text-align: center;
    }
    @media #{$mediaMaxWidth1199} {
      padding: 10px 0 20px 0;
    }
  }

  .color-emphasis {
    color: $maxSecondaryBlue100;
    font-weight: 600;
  }

</style>
