<template>
  <div class="home-container">
    <DeveloperArea/>
    <HelloDev/>
    <BenefitsSection/>
    <FinalSection/>
  </div>
</template>
  
<script setup>
import { onMounted, onUnmounted } from 'vue';
import DeveloperArea from './DeveloperArea.vue'
import HelloDev from './HelloDev.vue'
import BenefitsSection from './BenefitsSection.vue';
import FinalSection from './FinalSection.vue';
import { isDarkTheme } from '@/services/theme-state.js';

onMounted(() => {
  document.body.classList.add('dark-theme');
  isDarkTheme.value = true;
})

onUnmounted(() => {
  document.body.classList.remove('dark-theme');
  isDarkTheme.value = false;
})

</script>
  
<style lang="scss" scoped>
.home-container {
position: relative;
margin-top: -84px;
}
</style>