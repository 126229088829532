<template>
  <svg
    width="516"
    height="237"
    viewBox="0 0 516 237"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <circle
        cx="4.03271"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 4.03271 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="52.4038"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 52.4038 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="100.781"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 100.781 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="149.155"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 149.155 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="197.53"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 197.53 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="245.905"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 245.905 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="294.282"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 294.282 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="342.656"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 342.656 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="391.032"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 391.032 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="439.406"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 439.406 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="487.783"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 487.783 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="28.2178"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 28.2178 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="76.5933"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 76.5933 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="124.969"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 124.969 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="173.344"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 173.344 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="221.718"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 221.718 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="270.092"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 270.092 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="318.468"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 318.468 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="366.843"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 366.843 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="415.216"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 415.216 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="463.591"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 463.591 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="511.969"
        cy="232.969"
        r="4.03125"
        transform="rotate(-90 511.969 232.969)"
        fill="#C4C4C4"
      />
      <circle
        cx="4.03125"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 4.03125 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="52.4038"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 52.4038 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="100.781"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 100.781 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="149.155"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 149.155 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="197.53"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 197.53 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="245.905"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 245.905 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="294.282"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 294.282 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="342.656"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 342.656 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="391.032"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 391.032 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="439.406"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 439.406 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="487.783"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 487.783 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="28.2178"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 28.2178 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="76.5933"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 76.5933 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="124.969"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 124.969 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="173.344"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 173.344 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="221.718"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 221.718 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="270.092"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 270.092 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="318.468"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 318.468 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="366.843"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 366.843 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="415.216"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 415.216 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="463.591"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 463.591 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="511.969"
        cy="208.781"
        r="4.03125"
        transform="rotate(-90 511.969 208.781)"
        fill="#C4C4C4"
      />
      <circle
        cx="4.03125"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 4.03125 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="52.4038"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 52.4038 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="100.781"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 100.781 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="149.155"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 149.155 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="197.53"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 197.53 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="245.905"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 245.905 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="294.282"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 294.282 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="342.656"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 342.656 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="391.032"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 391.032 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="439.406"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 439.406 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="487.783"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 487.783 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="28.2178"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 28.2178 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="76.5933"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 76.5933 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="124.969"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 124.969 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="173.344"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 173.344 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="221.718"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 221.718 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="270.092"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 270.092 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="318.468"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 318.468 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="366.843"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 366.843 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="415.216"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 415.216 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="463.591"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 463.591 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="511.969"
        cy="184.594"
        r="4.03125"
        transform="rotate(-90 511.969 184.594)"
        fill="#C4C4C4"
      />
      <circle
        cx="4.03125"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 4.03125 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="52.4038"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 52.4038 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="100.781"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 100.781 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="149.155"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 149.155 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="197.529"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 197.529 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="245.905"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 245.905 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="294.282"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 294.282 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="342.656"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 342.656 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="391.032"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 391.032 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="439.406"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 439.406 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="487.783"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 487.783 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="28.2178"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 28.2178 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="76.5933"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 76.5933 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="124.969"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 124.969 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="173.344"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 173.344 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="221.718"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 221.718 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="270.092"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 270.092 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="318.468"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 318.468 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="366.843"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 366.843 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="415.216"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 415.216 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="463.591"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 463.591 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="511.969"
        cy="160.406"
        r="4.03125"
        transform="rotate(-90 511.969 160.406)"
        fill="#C4C4C4"
      />
      <circle
        cx="4.03125"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 4.03125 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="52.4038"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 52.4038 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="100.781"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 100.781 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="149.155"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 149.155 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="197.529"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 197.529 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="245.905"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 245.905 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="294.282"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 294.282 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="342.656"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 342.656 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="391.032"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 391.032 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="439.406"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 439.406 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="487.783"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 487.783 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="28.2178"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 28.2178 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="76.5933"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 76.5933 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="124.969"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 124.969 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="173.344"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 173.344 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="221.718"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 221.718 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="270.092"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 270.092 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="318.468"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 318.468 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="366.843"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 366.843 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="415.216"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 415.216 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="463.591"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 463.591 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="511.969"
        cy="136.219"
        r="4.03125"
        transform="rotate(-90 511.969 136.219)"
        fill="#C4C4C4"
      />
      <circle
        cx="4.03125"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 4.03125 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="52.4038"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 52.4038 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="100.781"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 100.781 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="149.155"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 149.155 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="197.529"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 197.529 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="245.905"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 245.905 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="294.282"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 294.282 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="342.656"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 342.656 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="391.032"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 391.032 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="439.406"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 439.406 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="487.783"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 487.783 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="28.2178"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 28.2178 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="76.5933"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 76.5933 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="124.969"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 124.969 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="173.344"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 173.344 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="221.718"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 221.718 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="270.092"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 270.092 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="318.468"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 318.468 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="366.843"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 366.843 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="415.216"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 415.216 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="463.591"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 463.591 112.031)"
        fill="#C4C4C4"
      />
      <circle
        cx="511.969"
        cy="112.031"
        r="4.03125"
        transform="rotate(-90 511.969 112.031)"
        fill="#C4C4C4"
      />
    </g>
    <g filter="url(#filter0_d_1825_62737)">
      <rect
        x="298"
        y="60"
        width="137"
        height="147.934"
        rx="19.2958"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M325.497 180.438H407.504V98.4311H325.497V180.438ZM325.497 188.639C323.219 188.639 321.282 187.842 319.688 186.247C318.093 184.652 317.296 182.716 317.296 180.438V98.4311C317.296 96.1532 318.093 94.2169 319.688 92.6223C321.282 91.0277 323.219 90.2304 325.497 90.2304H353.516C353.971 87.0413 355.429 84.4216 357.889 82.3714C360.35 80.3213 363.22 79.2962 366.5 79.2962C369.78 79.2962 372.651 80.3213 375.111 82.3714C377.571 84.4216 379.029 87.0413 379.485 90.2304H407.504C409.782 90.2304 411.718 91.0277 413.312 92.6223C414.907 94.2169 415.704 96.1532 415.704 98.4311V180.438C415.704 182.716 414.907 184.652 413.312 186.247C411.718 187.842 409.782 188.639 407.504 188.639H325.497ZM366.5 96.1076C367.776 96.1076 368.892 95.6292 369.849 94.6725C370.805 93.7157 371.284 92.5995 371.284 91.3239C371.284 90.0482 370.805 88.932 369.849 87.9752C368.892 87.0185 367.776 86.5401 366.5 86.5401C365.224 86.5401 364.108 87.0185 363.152 87.9752C362.195 88.932 361.716 90.0482 361.716 91.3239C361.716 92.5995 362.195 93.7157 363.152 94.6725C364.108 95.6292 365.224 96.1076 366.5 96.1076Z"
        fill="#202A44"
      />
      <path
        d="M357.48 155.152C358.3 154.332 358.71 153.353 358.71 152.214C358.71 151.075 358.3 150.095 357.48 149.275L346.545 138.341L357.48 127.407C358.3 126.587 358.71 125.607 358.71 124.468C358.71 123.329 358.3 122.349 357.48 121.529C356.66 120.709 355.68 120.299 354.541 120.299C353.402 120.299 352.423 120.709 351.602 121.529L337.661 135.471C336.841 136.291 336.431 137.27 336.431 138.409C336.431 139.548 336.841 140.528 337.661 141.348L351.466 155.152C352.286 155.972 353.288 156.382 354.473 156.382C355.657 156.382 356.66 155.972 357.48 155.152Z"
        fill="#9B6AFA"
      />
      <path
        d="M377.025 155.152C376.205 154.332 375.795 153.352 375.795 152.213C375.795 151.074 376.205 150.095 377.025 149.275L387.959 138.341L377.025 127.406C376.205 126.586 375.795 125.607 375.795 124.468C375.795 123.329 376.205 122.349 377.025 121.529C377.845 120.709 378.824 120.299 379.963 120.299C381.102 120.299 382.082 120.709 382.902 121.529L396.843 135.47C397.663 136.29 398.073 137.27 398.073 138.409C398.073 139.548 397.663 140.527 396.843 141.348L383.039 155.152C382.219 155.972 381.216 156.382 380.032 156.382C378.847 156.382 377.845 155.972 377.025 155.152Z"
        fill="#28D9EC"
      />
    </g>
    <g filter="url(#filter1_d_1825_62737)">
      <rect
        x="235"
        y="7"
        width="77"
        height="83.1455"
        rx="10.8451"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M250.454 74.6916H296.546V28.6001H250.454V74.6916ZM250.454 79.3008C249.174 79.3008 248.086 78.8527 247.19 77.9564C246.293 77.0602 245.845 75.9719 245.845 74.6916V28.6001C245.845 27.3197 246.293 26.2315 247.19 25.3352C248.086 24.439 249.174 23.9909 250.454 23.9909H266.202C266.458 22.1985 267.278 20.7261 268.661 19.5738C270.043 18.4215 271.656 17.8454 273.5 17.8454C275.344 17.8454 276.957 18.4215 278.34 19.5738C279.723 20.7261 280.542 22.1985 280.798 23.9909H296.546C297.826 23.9909 298.915 24.439 299.811 25.3352C300.707 26.2315 301.155 27.3197 301.155 28.6001V74.6916C301.155 75.9719 300.707 77.0602 299.811 77.9564C298.915 78.8527 297.826 79.3008 296.546 79.3008H250.454ZM273.5 27.2941C274.217 27.2941 274.844 27.0253 275.382 26.4875C275.92 25.9498 276.189 25.3224 276.189 24.6055C276.189 23.8885 275.92 23.2611 275.382 22.7234C274.844 22.1857 274.217 21.9168 273.5 21.9168C272.783 21.9168 272.156 22.1857 271.618 22.7234C271.08 23.2611 270.811 23.8885 270.811 24.6055C270.811 25.3224 271.08 25.9498 271.618 26.4875C272.156 27.0253 272.783 27.2941 273.5 27.2941Z"
        fill="#202A44"
      />
      <path
        d="M268.43 60.4795C268.891 60.0186 269.122 59.4681 269.122 58.8279C269.122 58.1878 268.891 57.6372 268.43 57.1763L262.285 51.0308L268.43 44.8852C268.891 44.4243 269.122 43.8738 269.122 43.2336C269.122 42.5935 268.891 42.0429 268.43 41.582C267.969 41.1211 267.419 40.8906 266.779 40.8906C266.138 40.8906 265.588 41.1211 265.127 41.582L257.291 49.4176C256.831 49.8785 256.6 50.429 256.6 51.0692C256.6 51.7093 256.831 52.2599 257.291 52.7208L265.05 60.4795C265.511 60.9404 266.074 61.1709 266.74 61.1709C267.406 61.1709 267.969 60.9404 268.43 60.4795Z"
        fill="#9B6AFA"
      />
      <path
        d="M279.415 60.4795C278.955 60.0186 278.724 59.4681 278.724 58.8279C278.724 58.1878 278.955 57.6372 279.415 57.1763L285.561 51.0308L279.415 44.8852C278.955 44.4243 278.724 43.8738 278.724 43.2336C278.724 42.5935 278.955 42.0429 279.415 41.582C279.876 41.1211 280.427 40.8906 281.067 40.8906C281.707 40.8906 282.258 41.1211 282.719 41.582L290.554 49.4176C291.015 49.8785 291.246 50.429 291.246 51.0692C291.246 51.7093 291.015 52.2599 290.554 52.7208L282.795 60.4795C282.335 60.9404 281.771 61.1709 281.105 61.1709C280.44 61.1709 279.876 60.9404 279.415 60.4795Z"
        fill="#28D9EC"
      />
    </g>
    <g filter="url(#filter2_d_1825_62737)">
      <rect
        x="153"
        y="73"
        width="61"
        height="65.8686"
        rx="8.59155"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M165.243 126.626H201.757V90.1116H165.243V126.626ZM165.243 130.277C164.229 130.277 163.367 129.922 162.657 129.212C161.947 128.502 161.592 127.64 161.592 126.626V90.1116C161.592 89.0973 161.947 88.2352 162.657 87.5252C163.367 86.8152 164.229 86.4602 165.243 86.4602H177.719C177.922 85.0402 178.571 83.8738 179.666 82.9609C180.762 82.0481 182.04 81.5917 183.5 81.5917C184.961 81.5917 186.239 82.0481 187.334 82.9609C188.43 83.8738 189.079 85.0402 189.282 86.4602H201.757C202.772 86.4602 203.634 86.8152 204.344 87.5252C205.054 88.2352 205.409 89.0973 205.409 90.1116V126.626C205.409 127.64 205.054 128.502 204.344 129.212C203.634 129.922 202.772 130.277 201.757 130.277H165.243ZM183.5 89.0771C184.068 89.0771 184.565 88.8641 184.991 88.4381C185.417 88.0121 185.63 87.5151 185.63 86.9471C185.63 86.3791 185.417 85.8821 184.991 85.4561C184.565 85.0301 184.068 84.8171 183.5 84.8171C182.932 84.8171 182.435 85.0301 182.009 85.4561C181.583 85.8821 181.37 86.3791 181.37 86.9471C181.37 87.5151 181.583 88.0121 182.009 88.4381C182.435 88.8641 182.932 89.0771 183.5 89.0771Z"
        fill="#202A44"
      />
      <path
        d="M179.484 115.367C179.849 115.002 180.031 114.566 180.031 114.058C180.031 113.551 179.849 113.115 179.484 112.75L174.615 107.881L179.484 103.013C179.849 102.648 180.031 102.212 180.031 101.705C180.031 101.197 179.849 100.761 179.484 100.396C179.119 100.031 178.682 99.8484 178.175 99.8484C177.668 99.8484 177.232 100.031 176.867 100.396L170.66 106.603C170.294 106.969 170.112 107.405 170.112 107.912C170.112 108.419 170.294 108.855 170.66 109.22L176.806 115.367C177.171 115.732 177.617 115.915 178.145 115.915C178.672 115.915 179.119 115.732 179.484 115.367Z"
        fill="#9B6AFA"
      />
      <path
        d="M188.186 115.367C187.821 115.002 187.638 114.566 187.638 114.058C187.638 113.551 187.821 113.115 188.186 112.75L193.055 107.881L188.186 103.013C187.821 102.648 187.638 102.212 187.638 101.705C187.638 101.197 187.821 100.761 188.186 100.396C188.551 100.031 188.987 99.8484 189.495 99.8484C190.002 99.8484 190.438 100.031 190.803 100.396L197.01 106.603C197.376 106.969 197.558 107.405 197.558 107.912C197.558 108.419 197.376 108.855 197.01 109.22L190.864 115.367C190.499 115.732 190.052 115.915 189.525 115.915C188.998 115.915 188.551 115.732 188.186 115.367Z"
        fill="#28D9EC"
      />
    </g>
    <g filter="url(#filter3_d_1825_62737)">
      <rect
        x="213"
        y="170"
        width="44.4522"
        height="48"
        rx="6.26087"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M221.922 209.078H248.531V182.47H221.922V209.078ZM221.922 211.739C221.183 211.739 220.555 211.481 220.037 210.963C219.52 210.446 219.261 209.818 219.261 209.078V182.47C219.261 181.731 219.52 181.102 220.037 180.585C220.555 180.068 221.183 179.809 221.922 179.809H231.013C231.161 178.774 231.634 177.924 232.433 177.259C233.231 176.594 234.162 176.261 235.226 176.261C236.291 176.261 237.222 176.594 238.02 177.259C238.819 177.924 239.292 178.774 239.439 179.809H248.531C249.27 179.809 249.898 180.068 250.416 180.585C250.933 181.102 251.192 181.731 251.192 182.47V209.078C251.192 209.818 250.933 210.446 250.416 210.963C249.898 211.481 249.27 211.739 248.531 211.739H221.922ZM235.226 181.716C235.64 181.716 236.003 181.561 236.313 181.25C236.623 180.94 236.779 180.578 236.779 180.164C236.779 179.75 236.623 179.388 236.313 179.077C236.003 178.767 235.64 178.612 235.226 178.612C234.813 178.612 234.45 178.767 234.14 179.077C233.829 179.388 233.674 179.75 233.674 180.164C233.674 180.578 233.829 180.94 234.14 181.25C234.45 181.561 234.813 181.716 235.226 181.716Z"
        fill="#202A44"
      />
      <path
        d="M232.299 200.874C232.565 200.608 232.698 200.29 232.698 199.92C232.698 199.551 232.565 199.233 232.299 198.967L228.751 195.419L232.299 191.871C232.565 191.605 232.698 191.287 232.698 190.918C232.698 190.548 232.565 190.23 232.299 189.964C232.033 189.698 231.715 189.565 231.346 189.565C230.976 189.565 230.658 189.698 230.392 189.964L225.869 194.488C225.603 194.754 225.47 195.072 225.47 195.441C225.47 195.811 225.603 196.129 225.869 196.395L230.348 200.874C230.614 201.14 230.939 201.273 231.324 201.273C231.708 201.273 232.033 201.14 232.299 200.874Z"
        fill="#9B6AFA"
      />
      <path
        d="M238.641 200.874C238.375 200.608 238.242 200.29 238.242 199.92C238.242 199.551 238.375 199.233 238.641 198.967L242.189 195.419L238.641 191.871C238.375 191.605 238.242 191.287 238.242 190.918C238.242 190.548 238.375 190.23 238.641 189.964C238.907 189.698 239.225 189.565 239.595 189.565C239.964 189.565 240.282 189.698 240.548 189.964L245.072 194.488C245.338 194.754 245.471 195.072 245.471 195.441C245.471 195.811 245.338 196.129 245.072 196.395L240.592 200.874C240.326 201.14 240.001 201.273 239.617 201.273C239.232 201.273 238.907 201.14 238.641 200.874Z"
        fill="#28D9EC"
      />
    </g>
    <g filter="url(#filter4_d_1825_62737)">
      <rect
        x="82"
        y="147"
        width="44.4522"
        height="48"
        rx="6.26087"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M90.9221 186.078H117.531V159.47H90.9221V186.078ZM90.9221 188.739C90.183 188.739 89.5547 188.481 89.0373 187.963C88.5199 187.446 88.2612 186.818 88.2612 186.078V159.47C88.2612 158.731 88.5199 158.102 89.0373 157.585C89.5547 157.068 90.183 156.809 90.9221 156.809H100.013C100.161 155.774 100.634 154.924 101.433 154.259C102.231 153.594 103.162 153.261 104.226 153.261C105.291 153.261 106.222 153.594 107.02 154.259C107.819 154.924 108.292 155.774 108.439 156.809H117.531C118.27 156.809 118.898 157.068 119.416 157.585C119.933 158.102 120.192 158.731 120.192 159.47V186.078C120.192 186.818 119.933 187.446 119.416 187.963C118.898 188.481 118.27 188.739 117.531 188.739H90.9221ZM104.226 158.716C104.64 158.716 105.003 158.561 105.313 158.25C105.623 157.94 105.779 157.578 105.779 157.164C105.779 156.75 105.623 156.388 105.313 156.077C105.003 155.767 104.64 155.612 104.226 155.612C103.813 155.612 103.45 155.767 103.14 156.077C102.829 156.388 102.674 156.75 102.674 157.164C102.674 157.578 102.829 157.94 103.14 158.25C103.45 158.561 103.813 158.716 104.226 158.716Z"
        fill="#202A44"
      />
      <path
        d="M101.299 177.874C101.565 177.608 101.698 177.29 101.698 176.92C101.698 176.551 101.565 176.233 101.299 175.967L97.7515 172.419L101.299 168.871C101.565 168.605 101.698 168.287 101.698 167.918C101.698 167.548 101.565 167.23 101.299 166.964C101.033 166.698 100.715 166.565 100.346 166.565C99.9762 166.565 99.6584 166.698 99.3923 166.964L94.8689 171.488C94.6028 171.754 94.4697 172.072 94.4697 172.441C94.4697 172.811 94.6028 173.129 94.8689 173.395L99.348 177.874C99.6141 178.14 99.9393 178.273 100.324 178.273C100.708 178.273 101.033 178.14 101.299 177.874Z"
        fill="#9B6AFA"
      />
      <path
        d="M107.641 177.874C107.375 177.608 107.242 177.29 107.242 176.92C107.242 176.551 107.375 176.233 107.641 175.967L111.189 172.419L107.641 168.871C107.375 168.605 107.242 168.287 107.242 167.918C107.242 167.548 107.375 167.23 107.641 166.964C107.907 166.698 108.225 166.565 108.595 166.565C108.964 166.565 109.282 166.698 109.548 166.964L114.072 171.488C114.338 171.754 114.471 172.072 114.471 172.441C114.471 172.811 114.338 173.129 114.072 173.395L109.592 177.874C109.326 178.14 109.001 178.273 108.617 178.273C108.232 178.273 107.907 178.14 107.641 177.874Z"
        fill="#28D9EC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1825_62737"
        x="286.423"
        y="48.4225"
        width="160.155"
        height="171.089"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood 
          flood-opacity="0" 
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5.78873" />
        <feComposite 
          in2="hardAlpha" 
          operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1825_62737"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1825_62737"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1825_62737"
        x="228.493"
        y="0.492958"
        width="90.0141"
        height="96.1596"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood 
          flood-opacity="0" 
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3.25352" />
        <feComposite 
          in2="hardAlpha" 
          operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1825_62737"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1825_62737"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1825_62737"
        x="147.845"
        y="67.8451"
        width="71.3099"
        height="76.1785"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood 
          flood-opacity="0" 
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.57746" />
        <feComposite 
          in2="hardAlpha" 
          operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1825_62737"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1825_62737"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_1825_62737"
        x="209.243"
        y="166.243"
        width="51.9652"
        height="55.513"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood 
          flood-opacity="0" 
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.87826" />
        <feComposite 
          in2="hardAlpha" 
          operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1825_62737"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1825_62737"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_1825_62737"
        x="78.2435"
        y="143.243"
        width="51.9652"
        height="55.513"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood 
          flood-opacity="0" 
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.87826" />
        <feComposite 
          in2="hardAlpha" 
          operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1825_62737"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1825_62737"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script setup></script>