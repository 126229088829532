const appsRoutes = [
    {
        path: '/apps',
        name: 'apps',
        component: () => import('@/views/AppsView.vue'),
        meta: { 
            title: 'Aplicativos',
            internalValidation: true
        },
        children: [
            {
                path: ':id',
                name: 'app',
                component: () => import('@/views/AppView.vue'),
                meta: {
                    internalValidation: true
                }
            }
        ]
    },
    {
        path: '/app/:id/status',
        name: 'app-status',
        component: () => import('@organisms/AppSuccess/AppSuccess.vue'),
        meta: {
            internalValidation: true
        }
    }
]

export default appsRoutes
