<template>
  <section class="benefits_container">
    <div class="benefits__title-container">
      <span class="benefits__angle-brackets">{{ $t('home.BenefitsSection.preTitle') }}</span>
      <span class="benefits__main-title">{{ $t('home.BenefitsSection.advantagesLine1') }}</span>
      <span class="benefits__main-title">{{ $t('home.BenefitsSection.advantagesLine2') }}</span>
      <span class="benefits__purple-color">{{ $t('home.BenefitsSection.advantagesLine3') }}</span>
      <span class="benefits__main-title">{{ $t('home.BenefitsSection.advantagesLine4') }}</span>
      <span class="benefits__purple-color">{{ $t('home.BenefitsSection.advantagesLine5') }}</span>
      <span class="benefits__angle-brackets">{{ $t('home.BenefitsSection.postTitle') }}</span>
    </div>
    <div class="benefits__list">
      <div
        v-for="(benefit, index) in benefits"
        :key="index"
        class="benefits__benefit-card"
      >
        <img
          src="/src/assets/homeView/code_box.svg"
          class="benefits__benefit-icon"
        />
        <AmTypography
          :text="$t(benefit.titleKey)"
          fontType="sarabun"
          typographyStyle="l6"
          textColor="text20"
          weight="bold"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { AmTypography } from '@appmax_npm/ds-appmax-v3';
import { ref } from 'vue';

const benefits = ref([
  {
    titleKey: 'home.BenefitsSection.installEasy'
  },
  {
    titleKey: 'home.BenefitsSection.earnOnInstall'
  },
  {
    titleKey: 'home.BenefitsSection.visibility'
  },
  {
    titleKey: 'home.BenefitsSection.lessFriction'
  },
]);
</script>

<style scoped lang="scss">
.benefits {
  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1134px;
    margin: -20px auto;
  }

  &__title-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 60px 261px;
    color: $white;
    font-family: $secondaryFont;
    font-size: 48px;
    font-weight: 700;

    @media #{$mediaMaxWidth768} {
      font-size: 24px;
      padding: 40px 30px;
      gap: 6px;
    }
    
    @media screen and (min-width: 991px) and (max-width: 1095px) {
    padding: 60px 200px;
    }
    @media screen and (min-width: 769px) and (max-width: 990px) {
    padding: 60px 93px;
    }
  }


  &__purple-color {
    color: $maxPrimaryPurple100;
  }

  &__main-title {
    color: $white;

    &:nth-child(3) {
      color: $maxSecondaryBlue100;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 132px;
    margin: 40px 10px;

    @media #{$mediaMaxWidth768} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 68px;
      margin: 40px 40px;
    }
  }

  &__benefit-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    gap: 32px;

    &__benefit-icon {
      width: 48px;
      margin-bottom: 32px;

      @media #{$mediaMaxWidth768} {
        margin-bottom: 16px;
        gap: 16px;
      }
    }
  }
}
</style>
