<template>
  <svg
    v-if="props.arrow === 'left' && mq.current !== 'mobile'"
    :width="props.width"
    viewBox="0 0 178 287"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M167.7 55.3004L167.711 55.29L167.722 55.2803C174.153 49.5633 177.375 42.0652 177.375 32.7376C177.375 23.4145 174.155 15.5436 167.711 9.09397C161.261 3.36514 153.563 0.5 144.592 0.5C135.62 0.5 127.923 3.36515 121.473 9.09399L10.1753 120.392C3.7196 126.847 0.500397 134.542 0.500397 143.5C0.500397 152.458 3.71902 160.151 10.1735 166.607C10.1741 166.607 10.1747 166.608 10.1753 166.608L121.482 276.823L121.483 276.825C127.939 283.281 135.633 286.5 144.592 286.5C153.55 286.5 161.244 283.281 167.7 276.825C174.156 270.369 177.375 262.675 177.375 253.717C177.375 244.758 174.156 237.064 167.7 230.608L87.4708 150.379C83.3703 146.279 83.3703 139.63 87.4708 135.53L167.7 55.3004Z"
      :stroke="props.color"
    />
  </svg>

  <svg
    v-else-if="props.arrow === 'left' && mq.current === 'mobile'"
    :width="props.width"
    viewBox="0 0 161 289"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 175.5L103.755 278.179C110.302 284.726 118.123 288 127.217 288C136.31 288 144.131 284.726 150.679 278.179C157.226 271.631 160.5 263.811 160.5 254.717C160.5 245.623 157.226 237.802 150.679 231.255L70.4493 151.025C66.5441 147.12 66.5441 140.789 70.4493 136.883L150.679 56.654C157.226 50.8339 160.5 43.1952 160.5 33.7376C160.5 24.2801 157.226 16.2776 150.679 9.73003C144.131 3.91 136.31 1 127.217 1C118.123 1 110.302 3.91 103.755 9.73003L1 112.5"
      stroke="#9B6AFA"
    />
  </svg>

  <svg
    v-else-if="props.arrow === 'right' && mq.current !== 'mobile'"
    :width="props.width"
    viewBox="0 0 178 287"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1749 55.3004L10.1645 55.29L10.1535 55.2803C3.7219 49.5633 0.5 42.0652 0.5 32.7376C0.5 23.4145 3.71986 15.5436 10.1645 9.09397C16.6145 3.36514 24.3119 0.5 33.2833 0.5C42.2547 0.5 49.9521 3.36515 56.4021 9.09399L167.7 120.392C174.155 126.847 177.375 134.542 177.375 143.5C177.375 152.458 174.156 160.151 167.701 166.607C167.701 166.607 167.7 166.608 167.7 166.608L56.3934 276.823L56.3917 276.825C49.936 283.281 42.2418 286.5 33.2833 286.5C24.3248 286.5 16.6305 283.281 10.1749 276.825C3.71917 270.369 0.5 262.675 0.5 253.717C0.5 244.758 3.71917 237.064 10.1749 230.608L90.4042 150.379C94.5047 146.279 94.5047 139.63 90.4042 135.53L10.1749 55.3004Z"
      :stroke="props.color"
    />
  </svg>

  <svg
    v-else-if="props.arrow === 'right' && mq.current === 'mobile'"
    :width="props.width"
    viewBox="0 0 161 289"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.5 175.5L57.7453 278.179C51.1977 284.726 43.3771 288 34.2833 288C25.1895 288 17.3688 284.726 10.8213 278.179C4.27377 271.631 1 263.811 1 254.717C1 245.623 4.27377 237.802 10.8213 231.255L91.0507 151.025C94.9559 147.12 94.9559 140.789 91.0507 136.883L10.8213 56.654C4.27377 50.8339 1 43.1952 1 33.7376C1 24.2801 4.27377 16.2776 10.8213 9.73003C17.3688 3.91 25.1895 1 34.2833 1C43.3771 1 51.1977 3.91 57.7453 9.73003L160.5 112.5"
      stroke="#9B6AFA"
    />
  </svg>
</template>

<script setup>
import { inject } from 'vue'

const mq = inject('mq')

const props = defineProps({
  arrow: {
    type: String,
    required: false,
    default: 'left'
  },
  width: {
    type: String,
    required: false,
    default: '178'
  },
  height: {
    type: String,
    required: false,
    default: '287'
  },
  color: {
    type: String,
    required: false,
    default: '#9B6AFA'
  }
})
</script>
