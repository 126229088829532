<template>
  <div :class="`create-account-forms create-account-forms-view_${mq.current}`">
    <AmLoading 
      v-if="loading"
      :isLoading="loading"
    />
    <AmToast
      v-if="showAlert"
      :message="alertMessage"
      :duration="3000"
      margin="mt-28"
      position="top-right"
      closable
    />
    <div
      v-if="!loading"
      :class="{
        'create-account-forms-desktop': mq.current === 'desktop',
        'create-account-forms-mobile': mq.current !== 'desktop'
      }"
    >
      <div class="create-account-forms_group">
        <AmInput
          v-model="state.companyName"
          class="create-account-forms_group-input_company-name"
          :label="$t('createAccount.form.companyName.label')"
          :placeholder="
            $t('createAccount.form.companyName.placeholder')
          "
          size="fullWidth"
          required
          :validations="rules.companyName"
          feedbackType="error"
          :isDarkTheme="true"
          dataTestName="create-account-form_group-input_cnpj"
        />
        <AmInput
          v-model="state.cnpj"
          class="create-account-forms_group-input_cnpj"
          :label="$t('createAccount.form.cnpj.label')"
          :placeholder="$t('createAccount.form.cnpj.placeholder')"
          size="fullWidth"
          maskFormat="##.###.###/####-##"
          required
          :validations="rules.cnpj"
          feedbackType="error"
          :isDarkTheme="true"
          dataTestName="create-account-form_group-input_cnpj"
        />
        <AmInput
          v-model="state.socialReason"
          class="create-account-forms_group-input_social-reason"
          :label="$t('createAccount.form.socialReason.label')"
          :placeholder="
            $t('createAccount.form.socialReason.placeholder')
          "
          size="fullWidth"
          required
          :validations="rules.socialReason"
          feedbackType="error"
          :isDarkTheme="true"
          dataTestName="create-account-form_group-input_social-reason"
        />
        <AmInput
          v-model="state.email"
          class="create-account-forms_group-input_email"
          :label="$t('createAccount.form.email.label')"
          :placeholder="emailPlaceholder"
          size="fullWidth"
          required
          :validations="rules.email"
          feedbackType="error"
          :isDarkTheme="true"
          dataTestName="create-account-form_group-input_email"
        />
        <AmInput
          v-model="state.password"
          class="create-account-forms_group-input_password"
          :label="$t('createAccount.form.password.label')"
          :placeholder="$t('createAccount.form.password.placeholder')"
          type="password"
          size="fullWidth"
          required
          :validations="rules.password"
          feedbackType="error"
          :isDarkTheme="true"
          dataTestName="create-account-form_group-input_password"
        />
        <AmInput
          v-model="state.confirmPassword"
          class="create-account-forms_group-input_confirm-password"
          :label="$t('createAccount.form.confirmPassword.label')"
          :placeholder="
            $t('createAccount.form.confirmPassword.placeholder')
          "
          type="password"
          size="fullWidth"
          required
          :validations="rules.confirmPassword"
          feedbackType="error"
          :isDarkTheme="true"
          dataTestName="create-account-form_group-input_confirm-password"
        />
        <div class="create-account-forms_group--actions">
          <AmCheckbox
            v-model="state.terms"
            class="create-account-forms_group--actions-checkbox"
            type="outlined"
            :disabled="false"
            :label="$t('createAccount.form.terms.label')"
            dataTestName="create-account-form_group--actions--checkbox"
          />
          <span
            :class="[{ 'text-sm': mq.current === 'desktop', 'text-xs': mq.current !== 'desktop' }, 'create-account-forms_group--actions--terms']"
            :style="{
              color: '#9B6AFA',
            }"
          >
            <a
              href="https://appstore-producao.s3.sa-east-1.amazonaws.com/assets/termos_appstore.pdf"
              target="_blank"
              class="font-medium"
            >
              {{$t('createAccount.form.terms.termsOfUse')}}
            </a>
          </span>
        </div>
        <ReCaptcha
          v-if="hasRecaptcha"
          :siteKey="siteKey"
          recaptchaId="create-recaptcha"
          @captcha-verified="onRecaptchaChange"
        />
        <AmButton
          class="create-account-forms--button"
          :label="$t('createAccount.form.actions.submit')"
          variant="maxPrimary"
          type="default"
          size="xlarge"
          :disabled="!formValid || !state.terms"
          @eventKeyAmButton="submitForm"
          @click="submitForm"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { cnpj } from 'cpf-cnpj-validator';
import { computed, inject, reactive, ref, watch } from 'vue'
import { Api } from '@/services/axios'
import {
  AmButton,
  AmCheckbox,
  AmInput,
  AmLoading,
  AmToast
} from '@appmax_npm/ds-appmax-v3'
import { minLength, required, email, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import './CreateAccountForm.scss'
import ReCaptcha from '../../atoms/ReCaptcha/ReCaptcha.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import _ from 'lodash'

const env = inject('env')
const { t } = useI18n()
const loading = ref(false)
const router = useRouter()
const mq = inject('mq')
const showAlert = ref(false)
const alertMessage = ref(null)

const state = reactive({
  companyName: '',
  cnpj: '',
  socialReason: '',
  email: '',
  password: '',
  confirmPassword: '',
  terms: false,
  recaptchaToken: ''
})

const rules = computed(() => ({
  companyName: {
    required: helpers.withMessage(
      `${t('createAccount.form.companyName.error.required')}`,
      required
    )
  },
  cnpj: {
    required: helpers.withMessage(
      `${t('createAccount.form.cnpj.error.required')}`,
      required
    ),
    minLength: helpers.withMessage(
      `${t('createAccount.form.cnpj.error.minLength')}`,
      minLength(18)
    ),
    isInvalid: helpers.withMessage(
      `${t('createAccount.form.cnpj.error.invalid')}`,
      value => cnpj.isValid(value)
    )
  },
  socialReason: {
    required: helpers.withMessage(
      `${t('createAccount.form.socialReason.error.required')}`,
      required
    )
  },
  email: {
    required: helpers.withMessage(
      `${t('createAccount.form.email.error.required')}`,
      required
    ),
    email: helpers.withMessage(
      `${t('createAccount.form.email.error.invalid')}`,
      email
    )
  },
  password: {
    required: helpers.withMessage(
      `${t('createAccount.form.password.error.required')}`,
      required
    ),
    minLength: helpers.withMessage(
      `${t('createAccount.form.password.error.minLength')}`,
      minLength(8)
    )
  },
  confirmPassword: {
    required: helpers.withMessage(
      `${t('createAccount.form.confirmPassword.error.required')}`,
      required
    ),
    minLength: helpers.withMessage(
      `${t('createAccount.form.confirmPassword.error.minLength')}`,
      minLength(8)
    ),
    samePassword: helpers.withMessage(
      `${t('createAccount.form.confirmPassword.error.notMatch')}`,
      value => samePasswords(value)
    )
  },
  terms: {
    required: helpers.withMessage(
      `${t('createAccount.form.terms.error.required')}`,
      required
    )
  }
}))

const v$ = useVuelidate(rules, state)
const formValid = ref(false)

const validForm = async () => {
  const validations = {
    companyName: await v$.value.companyName.$validate(),
    cnpj: await v$.value.cnpj.$validate(),
    socialReason: await v$.value.socialReason.$validate(),
    email: await v$.value.email.$validate(),
    password: await v$.value.password.$validate(),
    confirmPassword: await v$.value.confirmPassword.$validate(),
    terms: await v$.value.terms.$validate()
  }

  return Object.values(validations).every(validation => validation)
}

watch(
  () => state,
  async () => {
    formValid.value = await validForm()
  },
  { deep: true }
)

const submitForm = async () => {
  loading.value = true
  const isValid = formValid.value

  if (!isValid || !state.terms) {
    return
  }

  await Api.post(`/auth/user/create`, {
    company_name: state.companyName,
    cnpj: _.replace(state.cnpj, /\D/g, ''),
    social_reason: state.socialReason,
    email: state.email,
    password: state.password,
    terms_of_use: state.terms,
    recaptcha_token:
      import.meta.env.VITE_APP_LOCAL === 'local'
        ? 'local'
        : state.recaptchaToken
  })
    .then(() => {
      loading.value = false

      router.push({ name: 'user-created' })
    })
    .catch(error => {
      loading.value = false
      showAlert.value = true

      alertMessage.value = {
        alertType: 'warning',
        title: `${t('createAccount.handleError.title')}`,
        description: `${t(`createAccount.handleError.${error.response.data.data.translate}`)}`
      }
    })
}

const hasRecaptcha = computed(() => {
  return env.recaptcha.on && env.recaptcha.apiKeyRecaptchaV2
})

const siteKey = computed(() => {
  return hasRecaptcha.value ? env.recaptcha.apiKeyRecaptchaV2 : ''
})

const onRecaptchaChange = response => {
  state.recaptchaToken = response
}

const samePasswords = (value) => {
  return value === state.password;
}

const emailPlaceholder = computed(() => {
  return t('createAccount.form.email.placeholder') + '@gmail.com'
})
</script>
