import { useAppStore } from '@/stores/modules/app'
import { AppStatus } from '@/enums/AppStatus'

export default async function (to, from, next) {
    const appStore = useAppStore()
    const response = await appStore.getAppById(+to.params.id)

    if (response.statusID === AppStatus.PUBLISHED_ID) {
        return next()
    }

    next({ name: 'apps' })
}
