<template>
  <div
    class="user-created"
  >
    <AmFeedback
      :title="$t('userCreated.title')"
      :subtitle="$t('userCreated.subtitle')"
      :description="$t('userCreated.description')"
      icon="checked"
    >
      <template
        #feedback>
        <AmIcon
          :class="['userCreated--icon', ...iconClasses]"
          name="done"
          color="#fff" />
      </template>
    </AmFeedback>
  </div>
</template>

<script>
import { AmIcon } from '@appmax_npm/ds-appmax-v3'
import AmFeedback from '../../molecules/AmFeedback/AmFeedback.vue'

export default {
  name: 'UserCreated',
  components: { AmIcon, AmFeedback },
  setup() {
    const iconClasses = ['bg-appmaxPurple rounded-xl p-1 flex items-center justify-center']
    return {
      iconClasses
    }
  }
}
</script>
