import { defineStore } from 'pinia'

export const useReturnBarStore = defineStore('returnBar', {
    state: () => {
        return {
            title: undefined,
            pageTitle: '',
            action: {
                type: 'button',
                title: '',
                path: ''
            }
        }
    },
    getters: {
        getAction: (state) => state.action,
        getTitle: (state) => state.action.title,
    },
    actions: {
        setAction(action) {
            this.action = action
        },
        setTitle(title) {
            this.action.title = title
        },
        setPageTitle(title) {
            this.pageTitle = title
        },
        setPath(path) {
            this.action.path = path
        },
        resetTitle() {
            this.action = {
                ...this.action,
                title: '',
            }
        },
        resetPath() {
            this.action = {
                ...this.action,
                path: '',
            }
        },
        resetAll() {
            this.action = {
                ...this.action,
                title: '',
                path: '',
            }
        }
    }
})
