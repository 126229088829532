<template>
  <svg
    class="w-full"
    width="544"
    height="195"
    viewBox="0 0 544 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      width="544"
      height="195"
      viewBox="0 0 544 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        opacity="0.2">
        <circle
          cx="73.1304"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 73.1304 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="122.282"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 122.282 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="171.439"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 171.439 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="220.592"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 220.592 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="269.749"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 269.749 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="318.903"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 318.903 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="368.06"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 368.06 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="417.214"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 417.214 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="466.371"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 466.371 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="515.525"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 515.525 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="97.7047"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 97.7047 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="146.861"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 146.861 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="196.016"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 196.016 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="245.172"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 245.172 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="294.327"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 294.327 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="343.481"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 343.481 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="392.636"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 392.636 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="441.793"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 441.793 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="490.945"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 490.945 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="540.101"
          cy="190.903"
          r="4.09627"
          transform="rotate(-90 540.101 190.903)"
          fill="#C4C4C4"
        />
        <circle
          cx="73.1285"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 73.1285 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="122.282"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 122.282 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="171.439"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 171.439 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="220.592"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 220.592 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="269.749"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 269.749 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="318.903"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 318.903 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="368.06"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 368.06 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="417.214"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 417.214 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="466.371"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 466.371 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="515.525"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 515.525 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="97.7047"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 97.7047 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="146.861"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 146.861 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="196.016"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 196.016 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="245.172"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 245.172 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="294.327"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 294.327 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="343.481"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 343.481 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="392.636"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 392.636 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="441.793"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 441.793 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="490.945"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 490.945 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="540.101"
          cy="166.326"
          r="4.09627"
          transform="rotate(-90 540.101 166.326)"
          fill="#C4C4C4"
        />
        <circle
          cx="73.1285"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 73.1285 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="122.282"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 122.282 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="171.439"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 171.439 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="220.592"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 220.592 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="269.749"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 269.749 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="318.903"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 318.903 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="368.06"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 368.06 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="417.214"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 417.214 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="466.371"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 466.371 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="515.525"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 515.525 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="97.7047"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 97.7047 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="146.861"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 146.861 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="196.016"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 196.016 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="245.172"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 245.172 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="294.327"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 294.327 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="343.481"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 343.481 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="392.636"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 392.636 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="441.793"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 441.793 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="490.945"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 490.945 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="540.101"
          cy="141.747"
          r="4.09627"
          transform="rotate(-90 540.101 141.747)"
          fill="#C4C4C4"
        />
        <circle
          cx="73.1285"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 73.1285 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="122.282"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 122.282 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="171.439"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 171.439 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="220.592"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 220.592 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="269.747"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 269.747 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="318.903"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 318.903 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="368.06"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 368.06 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="417.214"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 417.214 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="466.371"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 466.371 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="515.525"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 515.525 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="97.7047"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 97.7047 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="146.861"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 146.861 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="196.016"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 196.016 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="245.172"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 245.172 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="294.327"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 294.327 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="343.481"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 343.481 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="392.636"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 392.636 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="441.793"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 441.793 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="490.945"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 490.945 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="540.101"
          cy="117.169"
          r="4.09627"
          transform="rotate(-90 540.101 117.169)"
          fill="#C4C4C4"
        />
        <circle
          cx="73.1285"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 73.1285 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="122.282"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 122.282 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="171.439"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 171.439 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="220.592"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 220.592 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="269.747"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 269.747 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="318.903"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 318.903 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="368.06"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 368.06 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="417.214"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 417.214 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="466.371"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 466.371 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="515.525"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 515.525 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="97.7047"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 97.7047 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="146.861"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 146.861 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="196.016"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 196.016 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="245.172"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 245.172 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="294.327"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 294.327 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="343.481"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 343.481 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="392.636"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 392.636 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="441.793"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 441.793 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="490.945"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 490.945 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="540.101"
          cy="92.5922"
          r="4.09627"
          transform="rotate(-90 540.101 92.5922)"
          fill="#C4C4C4"
        />
        <circle
          cx="73.1285"
          cy="68.0141"
          r="4.09627"
          transform="rotate(-90 73.1285 68.0141)"
          fill="#C4C4C4"
        />
        <circle
          cx="122.282"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 122.282 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="171.439"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 171.439 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="220.592"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 220.592 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="269.747"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 269.747 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="318.903"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 318.903 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="368.06"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 368.06 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="417.214"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 417.214 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="466.371"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 466.371 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="515.525"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 515.525 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="97.7047"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 97.7047 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="146.861"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 146.861 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="196.016"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 196.016 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="245.172"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 245.172 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="294.327"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 294.327 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="343.481"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 343.481 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="392.636"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 392.636 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="441.793"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 441.793 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="490.945"
          cy="68.0151"
          r="4.09627"
          transform="rotate(-90 490.945 68.0151)"
          fill="#C4C4C4"
        />
        <circle
          cx="540.101"
          cy="68.0141"
          r="4.09627"
          transform="rotate(-90 540.101 68.0141)"
          fill="#C4C4C4"
        />
      </g>
      <g
        filter="url(#filter0_d_916_32608)">
        <rect
          x="181.826"
          y="49.6328"
          width="112.787"
          height="121.789"
          rx="15.8856"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M204.463 148.786H271.977V81.272H204.463V148.786ZM204.463 155.537C202.588 155.537 200.994 154.881 199.681 153.568C198.368 152.255 197.712 150.661 197.712 148.786V81.272C197.712 79.3966 198.368 77.8025 199.681 76.4897C200.994 75.177 202.588 74.5206 204.463 74.5206H227.53C227.905 71.8951 229.106 69.7384 231.131 68.0505C233.157 66.3627 235.52 65.5188 238.22 65.5188C240.921 65.5188 243.284 66.3627 245.309 68.0505C247.334 69.7384 248.535 71.8951 248.91 74.5206H271.977C273.852 74.5206 275.446 75.177 276.759 76.4897C278.072 77.8025 278.728 79.3966 278.728 81.272V148.786C278.728 150.661 278.072 152.255 276.759 153.568C275.446 154.881 273.852 155.537 271.977 155.537H204.463ZM238.22 79.3591C239.27 79.3591 240.189 78.9652 240.977 78.1776C241.765 77.3899 242.158 76.471 242.158 75.4208C242.158 74.3706 241.765 73.4516 240.977 72.664C240.189 71.8763 239.27 71.4825 238.22 71.4825C237.17 71.4825 236.251 71.8763 235.463 72.664C234.676 73.4516 234.282 74.3706 234.282 75.4208C234.282 76.471 234.676 77.3899 235.463 78.1776C236.251 78.9652 237.17 79.3591 238.22 79.3591Z"
          fill="#202A44"
        />
        <path
          d="M230.793 127.969C231.468 127.294 231.806 126.487 231.806 125.549C231.806 124.612 231.468 123.805 230.793 123.13L221.792 114.128L230.793 105.127C231.468 104.451 231.806 103.645 231.806 102.707C231.806 101.77 231.468 100.963 230.793 100.288C230.118 99.613 229.312 99.2754 228.374 99.2754C227.436 99.2754 226.63 99.613 225.955 100.288L214.478 111.765C213.802 112.441 213.465 113.247 213.465 114.185C213.465 115.122 213.802 115.929 214.478 116.604L225.842 127.969C226.517 128.644 227.343 128.981 228.318 128.981C229.293 128.981 230.118 128.644 230.793 127.969Z"
          fill="#9B6AFA"
        />
        <path
          d="M246.884 127.969C246.209 127.294 245.872 126.487 245.872 125.549C245.872 124.612 246.209 123.805 246.884 123.13L255.886 114.128L246.884 105.127C246.209 104.451 245.872 103.645 245.872 102.707C245.872 101.77 246.209 100.963 246.884 100.288C247.56 99.613 248.366 99.2754 249.304 99.2754C250.241 99.2754 251.048 99.613 251.723 100.288L263.2 111.765C263.875 112.441 264.213 113.247 264.213 114.185C264.213 115.122 263.875 115.929 263.2 116.604L251.835 127.969C251.16 128.644 250.335 128.981 249.36 128.981C248.385 128.981 247.56 128.644 246.884 127.969Z"
          fill="#28D9EC"
        />
      </g>
      <g
        filter="url(#filter1_d_916_32608)">
        <rect
          x="129.96"
          y="6"
          width="63.3915"
          height="68.4509"
          rx="8.92838"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M142.683 61.7284H180.629V23.7828H142.683V61.7284ZM142.683 65.523C141.629 65.523 140.733 65.1541 139.995 64.4162C139.258 63.6784 138.889 62.7825 138.889 61.7284V23.7828C138.889 22.7288 139.258 21.8328 139.995 21.095C140.733 20.3572 141.629 19.9882 142.683 19.9882H155.648C155.859 18.5126 156.533 17.3004 157.672 16.3518C158.81 15.4032 160.138 14.9288 161.656 14.9288C163.174 14.9288 164.502 15.4032 165.64 16.3518C166.779 17.3004 167.453 18.5126 167.664 19.9882H180.629C181.683 19.9882 182.579 20.3572 183.317 21.095C184.054 21.8328 184.423 22.7288 184.423 23.7828V61.7284C184.423 62.7825 184.054 63.6784 183.317 64.4162C182.579 65.1541 181.683 65.523 180.629 65.523H142.683ZM161.656 22.7077C162.246 22.7077 162.763 22.4863 163.205 22.0436C163.648 21.6009 163.87 21.0845 163.87 20.4942C163.87 19.9039 163.648 19.3874 163.205 18.9447C162.763 18.502 162.246 18.2807 161.656 18.2807C161.066 18.2807 160.549 18.502 160.107 18.9447C159.664 19.3874 159.443 19.9039 159.443 20.4942C159.443 21.0845 159.664 21.6009 160.107 22.0436C160.549 22.4863 161.066 22.7077 161.656 22.7077Z"
          fill="#202A44"
        />
        <path
          d="M157.482 50.0283C157.861 49.6488 158.051 49.1956 158.051 48.6685C158.051 48.1415 157.861 47.6883 157.482 47.3088L152.422 42.2494L157.482 37.19C157.861 36.8105 158.051 36.3573 158.051 35.8303C158.051 35.3032 157.861 34.85 157.482 34.4706C157.102 34.0911 156.649 33.9014 156.122 33.9014C155.595 33.9014 155.142 34.0911 154.762 34.4706L148.311 40.9213C147.932 41.3008 147.742 41.754 147.742 42.281C147.742 42.808 147.932 43.2613 148.311 43.6407L154.699 50.0283C155.078 50.4077 155.542 50.5974 156.09 50.5974C156.638 50.5974 157.102 50.4077 157.482 50.0283Z"
          fill="#9B6AFA"
        />
        <path
          d="M166.525 50.0283C166.146 49.6488 165.956 49.1956 165.956 48.6685C165.956 48.1415 166.146 47.6883 166.525 47.3088L171.585 42.2494L166.525 37.19C166.146 36.8105 165.956 36.3573 165.956 35.8303C165.956 35.3032 166.146 34.85 166.525 34.4706C166.905 34.0911 167.358 33.9014 167.885 33.9014C168.412 33.9014 168.865 34.0911 169.245 34.4706L175.695 40.9213C176.075 41.3008 176.265 41.754 176.265 42.281C176.265 42.808 176.075 43.2613 175.695 43.6407L169.308 50.0283C168.928 50.4077 168.465 50.5974 167.917 50.5974C167.369 50.5974 166.905 50.4077 166.525 50.0283Z"
          fill="#28D9EC"
        />
      </g>
      <g
        filter="url(#filter2_d_916_32608)">
        <rect
          x="62.4521"
          y="60.3359"
          width="50.2192"
          height="54.2273"
          rx="7.07313"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M72.5315 104.484H102.592V74.4235H72.5315V104.484ZM72.5315 107.49C71.6964 107.49 70.9867 107.198 70.4022 106.614C69.8176 106.029 69.5254 105.319 69.5254 104.484V74.4235C69.5254 73.5884 69.8176 72.8787 70.4022 72.2942C70.9867 71.7096 71.6964 71.4174 72.5315 71.4174H82.8022C82.9693 70.2484 83.5037 69.2881 84.4055 68.5366C85.3073 67.785 86.3594 67.4093 87.5619 67.4093C88.7643 67.4093 89.8164 67.785 90.7183 68.5366C91.6201 69.2881 92.1545 70.2484 92.3215 71.4174H102.592C103.427 71.4174 104.137 71.7096 104.722 72.2942C105.306 72.8787 105.598 73.5884 105.598 74.4235V104.484C105.598 105.319 105.306 106.029 104.722 106.614C104.137 107.198 103.427 107.49 102.592 107.49H72.5315ZM87.5619 73.5717C88.0295 73.5717 88.4386 73.3964 88.7894 73.0457C89.1401 72.695 89.3154 72.2858 89.3154 71.8182C89.3154 71.3506 89.1401 70.9414 88.7894 70.5907C88.4386 70.24 88.0295 70.0646 87.5619 70.0646C87.0943 70.0646 86.6851 70.24 86.3344 70.5907C85.9837 70.9414 85.8083 71.3506 85.8083 71.8182C85.8083 72.2858 85.9837 72.695 86.3344 73.0457C86.6851 73.3964 87.0943 73.5717 87.5619 73.5717Z"
          fill="#202A44"
        />
        <path
          d="M84.2556 95.2153C84.5563 94.9147 84.7066 94.5556 84.7066 94.1381C84.7066 93.7206 84.5563 93.3615 84.2556 93.0609L80.2475 89.0528L84.2556 85.0447C84.5563 84.7441 84.7066 84.3851 84.7066 83.9675C84.7066 83.55 84.5563 83.191 84.2556 82.8904C83.955 82.5898 83.596 82.4395 83.1785 82.4395C82.761 82.4395 82.4019 82.5898 82.1013 82.8904L76.991 88.0007C76.6903 88.3013 76.54 88.6604 76.54 89.0779C76.54 89.4954 76.6903 89.8545 76.991 90.1551L82.0512 95.2153C82.3518 95.5159 82.7192 95.6662 83.1534 95.6662C83.5876 95.6662 83.955 95.5159 84.2556 95.2153Z"
          fill="#9B6AFA"
        />
        <path
          d="M91.4201 95.2153C91.1195 94.9147 90.9692 94.5556 90.9692 94.1381C90.9692 93.7206 91.1195 93.3615 91.4201 93.0609L95.4282 89.0528L91.4201 85.0447C91.1195 84.7441 90.9692 84.3851 90.9692 83.9675C90.9692 83.55 91.1195 83.191 91.4201 82.8904C91.7207 82.5898 92.0798 82.4395 92.4973 82.4395C92.9148 82.4395 93.2739 82.5898 93.5745 82.8904L98.6848 88.0007C98.9854 88.3013 99.1357 88.6604 99.1357 89.0779C99.1357 89.4954 98.9854 89.8545 98.6848 90.1551L93.6246 95.2153C93.324 95.5159 92.9566 95.6662 92.5224 95.6662C92.0882 95.6662 91.7207 95.5159 91.4201 95.2153Z"
          fill="#28D9EC"
        />
      </g>
      <g
        filter="url(#filter3_d_916_32608)">
        <rect
          x="111.848"
          y="140.192"
          width="36.596"
          height="39.5168"
          rx="5.15436"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M119.193 172.364H141.099V150.458H119.193V172.364ZM119.193 174.555C118.584 174.555 118.067 174.342 117.641 173.916C117.215 173.49 117.002 172.973 117.002 172.364V150.458C117.002 149.85 117.215 149.332 117.641 148.906C118.067 148.481 118.584 148.268 119.193 148.268H126.677C126.799 147.416 127.188 146.716 127.845 146.168C128.503 145.621 129.269 145.347 130.146 145.347C131.022 145.347 131.789 145.621 132.446 146.168C133.103 146.716 133.492 147.416 133.614 148.268H141.099C141.707 148.268 142.224 148.481 142.65 148.906C143.076 149.332 143.289 149.85 143.289 150.458V172.364C143.289 172.973 143.076 173.49 142.65 173.916C142.224 174.342 141.707 174.555 141.099 174.555H119.193ZM130.146 149.837C130.486 149.837 130.784 149.71 131.04 149.454C131.296 149.199 131.423 148.9 131.423 148.56C131.423 148.219 131.296 147.921 131.04 147.665C130.784 147.41 130.486 147.282 130.146 147.282C129.805 147.282 129.507 147.41 129.251 147.665C128.996 147.921 128.868 148.219 128.868 148.56C128.868 148.9 128.996 149.199 129.251 149.454C129.507 149.71 129.805 149.837 130.146 149.837Z"
          fill="#202A44"
        />
        <path
          d="M127.736 165.61C127.955 165.391 128.064 165.129 128.064 164.825C128.064 164.521 127.955 164.259 127.736 164.04L124.815 161.119L127.736 158.198C127.955 157.979 128.064 157.718 128.064 157.413C128.064 157.109 127.955 156.847 127.736 156.628C127.517 156.409 127.255 156.3 126.951 156.3C126.647 156.3 126.385 156.409 126.166 156.628L122.442 160.352C122.223 160.571 122.113 160.833 122.113 161.137C122.113 161.442 122.223 161.703 122.442 161.922L126.129 165.61C126.348 165.829 126.616 165.938 126.933 165.938C127.249 165.938 127.517 165.829 127.736 165.61Z"
          fill="#9B6AFA"
        />
        <path
          d="M132.957 165.61C132.737 165.391 132.628 165.129 132.628 164.825C132.628 164.521 132.737 164.259 132.957 164.04L135.877 161.119L132.957 158.198C132.737 157.979 132.628 157.718 132.628 157.413C132.628 157.109 132.737 156.847 132.957 156.628C133.176 156.409 133.437 156.3 133.742 156.3C134.046 156.3 134.307 156.409 134.526 156.628L138.251 160.352C138.47 160.571 138.579 160.833 138.579 161.137C138.579 161.442 138.47 161.703 138.251 161.922L134.563 165.61C134.344 165.829 134.076 165.938 133.76 165.938C133.443 165.938 133.176 165.829 132.957 165.61Z"
          fill="#28D9EC"
        />
      </g>
      <g
        filter="url(#filter4_d_916_32608)">
        <rect
          x="4"
          y="121.257"
          width="36.596"
          height="39.5168"
          rx="5.15436"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M11.3449 153.429H33.2509V131.523H11.3449V153.429ZM11.3449 155.619C10.7364 155.619 10.2192 155.406 9.79322 154.98C9.36727 154.554 9.1543 154.037 9.1543 153.429V131.523C9.1543 130.914 9.36727 130.397 9.79322 129.971C10.2192 129.545 10.7364 129.332 11.3449 129.332H18.8295C18.9512 128.48 19.3406 127.78 19.9978 127.233C20.655 126.685 21.4217 126.411 22.2979 126.411C23.1742 126.411 23.9409 126.685 24.598 127.233C25.2552 127.78 25.6447 128.48 25.7664 129.332H33.2509C33.8594 129.332 34.3767 129.545 34.8026 129.971C35.2286 130.397 35.4415 130.914 35.4415 131.523V153.429C35.4415 154.037 35.2286 154.554 34.8026 154.98C34.3767 155.406 33.8594 155.619 33.2509 155.619H11.3449ZM22.2979 130.902C22.6387 130.902 22.9368 130.774 23.1924 130.519C23.448 130.263 23.5758 129.965 23.5758 129.624C23.5758 129.283 23.448 128.985 23.1924 128.73C22.9368 128.474 22.6387 128.346 22.2979 128.346C21.9572 128.346 21.659 128.474 21.4034 128.73C21.1478 128.985 21.0201 129.283 21.0201 129.624C21.0201 129.965 21.1478 130.263 21.4034 130.519C21.659 130.774 21.9572 130.902 22.2979 130.902Z"
          fill="#202A44"
        />
        <path
          d="M19.8882 146.673C20.1072 146.454 20.2168 146.193 20.2168 145.888C20.2168 145.584 20.1072 145.322 19.8882 145.103L16.9674 142.183L19.8882 139.262C20.1072 139.043 20.2168 138.781 20.2168 138.477C20.2168 138.173 20.1072 137.911 19.8882 137.692C19.6691 137.473 19.4075 137.363 19.1032 137.363C18.799 137.363 18.5373 137.473 18.3182 137.692L14.5942 141.416C14.3752 141.635 14.2656 141.897 14.2656 142.201C14.2656 142.505 14.3752 142.767 14.5942 142.986L18.2817 146.673C18.5008 146.892 18.7685 147.002 19.085 147.002C19.4014 147.002 19.6691 146.892 19.8882 146.673Z"
          fill="#9B6AFA"
        />
        <path
          d="M25.1089 146.673C24.8898 146.454 24.7803 146.193 24.7803 145.888C24.7803 145.584 24.8898 145.322 25.1089 145.103L28.0297 142.183L25.1089 139.262C24.8898 139.043 24.7803 138.781 24.7803 138.477C24.7803 138.173 24.8898 137.911 25.1089 137.692C25.328 137.473 25.5896 137.363 25.8939 137.363C26.1981 137.363 26.4598 137.473 26.6788 137.692L30.4029 141.416C30.6219 141.635 30.7314 141.897 30.7314 142.201C30.7314 142.505 30.6219 142.767 30.4029 142.986L26.7153 146.673C26.4963 146.892 26.2285 147.002 25.9121 147.002C25.5957 147.002 25.328 146.892 25.1089 146.673Z"
          fill="#28D9EC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_916_32608"
          x="172.295"
          y="40.1015"
          width="131.85"
          height="140.852"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur
            stdDeviation="4.76567" />
          <feComposite
            in2="hardAlpha"
            operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_916_32608" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_916_32608"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_916_32608"
          x="124.603"
          y="0.642974"
          width="74.1057"
          height="79.1652"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur
            stdDeviation="2.67851" />
          <feComposite
            in2="hardAlpha"
            operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_916_32608" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_916_32608"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_916_32608"
          x="58.2083"
          y="56.0921"
          width="58.7065"
          height="62.7153"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur
            stdDeviation="2.12194" />
          <feComposite
            in2="hardAlpha"
            operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_916_32608" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_916_32608"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_916_32608"
          x="108.755"
          y="137.1"
          width="42.7809"
          height="45.7018"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur
            stdDeviation="1.54631" />
          <feComposite
            in2="hardAlpha"
            operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_916_32608" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_916_32608"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_916_32608"
          x="0.907384"
          y="118.164"
          width="42.7809"
          height="45.7018"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur
            stdDeviation="1.54631" />
          <feComposite
            in2="hardAlpha"
            operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_916_32608" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_916_32608"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </svg>
</template>

<script>
export default {
  name: 'FirstAccessContentSvg'
}
</script>