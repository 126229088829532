const AppStatus = {
  DEVELOPMENT_ID: 1,
  DEVELOPMENT: 'development',

  ADJUSTMENT_PENDING_ID: 2,
  ADJUSTMENT_PENDING: 'adjustment-pending',

  IN_ANALYSIS_ID: 3,
  IN_ANALYSIS: 'in-analysis',

  PUBLISHED_ID: 4,
  PUBLISHED: 'published',

  REPROVED_ID: 5,
  REPROVED: 'reproved',

  DEACTIVATE_ID: 6,
  DEACTIVATE: 'deactivate',

  IN_DRAFT_ID: 7,
  IN_DRAFT: 'in-draft'
};

Object.freeze(AppStatus);

function allAppStatus() {
  return {
      [AppStatus.DEVELOPMENT_ID]: AppStatus.DEVELOPMENT,
      [AppStatus.ADJUSTMENT_PENDING_ID]: AppStatus.ADJUSTMENT_PENDING,
      [AppStatus.IN_ANALYSIS_ID]: AppStatus.IN_ANALYSIS,
      [AppStatus.PUBLISHED_ID]: AppStatus.PUBLISHED,
      [AppStatus.REPROVED_ID]: AppStatus.REPROVED,
      [AppStatus.DEACTIVATE_ID]: AppStatus.DEACTIVATE,
      [AppStatus.IN_DRAFT_ID]: AppStatus.IN_DRAFT
  };
}

export { AppStatus, allAppStatus };
