import { defineStore } from 'pinia'

export const useNavbarStore = defineStore('navbar', {
  state: () => {
    return {
      mode: 'public',
      options: {
        default: [
          {
            name: 'firstAccess',
            path: '/first-access',
            mode: 'private',
          },
          {
            name: 'documentation',
            mode: 'public',
            url: 'https://appmax.readme.io'
          },
          {
            name: 'apps',
            path: '/apps',
            mode: 'private',
          },
          {
            name: 'create-app',
            path: '/app/create',
            mode: 'private',
          },
        ],
      },
    }
  },
  getters: {
    getStatusAuthentication: (state) => state.isAuthentication,
    isAuthenticationMode: (state) => state.mode === 'authentication',
    isPublicMode: (state) => state.mode === 'public',
    isPrivateMode: (state) => state.mode === 'private',
  },
  actions: {
    setStatusAuthentication(status) {
      this.isAuthentication = status
    },
    setIsAuthenticated(status) {
      this.isAuthenticated = status
    },
    getOptionsByMode(mode) {
        return this.options.default.filter((option) => option.mode === mode || option.mode === 'public')
    },
    setAuthenticationMode() {
      this.mode = 'authentication'
    },
    setPrivateMode() {
      this.mode = 'private'
    },
    setPublicMode() {
      this.mode = 'public'
    },
  },
})
