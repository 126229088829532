<template>
  <section class="final-section">
    <div class="final-section__centralizer">
      <div class="final-section__image-and-backlight">
        <div class="final-section__macbook-backlight"></div>
        <img
          src="/src/assets/homeView/macbook.webp"
          class="final-section__macbook-image"
        />
      </div>
      <div class="final-section__text-container">
        <h2 class="final-section__text-container-title">
          {{ $t('home.appMaxStore.helpGrowTitle') }}
        </h2>
        <AmTypography
          :text="$t('home.appMaxStore.helpGrowDescription')"
          :fontType="mq.current === 'desktop' ? 'sarabun' : 'roboto'"
          :typographyStyle="mq.current !== 'desktop' ? 'l6' : 'l1'"
          textColor="text20"
          weight="normal"
          class="final-section__text-container-description"
        />
        <div class="final-section__final-button">
          <AmButton
            variant="maxPrimary"
            type="rounded"
            :label="$t('home.appMaxStore.createYourAppButton')"
            size="xxlarge"
            class="final-section__develop-app-button"
            to="/login"
          />
        </div>
      </div>
    </div>
  </section>
  <div class="footer-container">
    <div class="footer-container__text">
      <div class="footer-container__logo">
        <img
          src="/src/assets/homeView/logo_monocromatic.svg"
          alt="appmax"
        />
        <AmTypography
          :text="$t('home.appMaxStore.storeSlogan')"
          fontType="sarabun"
          :typographyStyle="mq.current !== 'desktop' ? 'l5' : 'l0'"
          textColor="white"
          weight="medium"
          class="final-section__description-text"
        />
      </div>
      <div class="footer-container__maximize-text">
        <AmTypography
          :text="$t('home.appMaxStore.maximizeService')"
          fontType="sarabun"
          :typographyStyle="mq.current !== 'desktop' ? 'l1' : 'extraSize'"
          textColor="white"
          weight="medium"
          class="final-section__description-text"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { AmTypography, AmButton } from '@appmax_npm/ds-appmax-v3'

const mq = inject('mq')
</script>

<style lang="scss" scoped>
.final-section {
  display: flex;
    align-items: center;
    text-align: center;
    padding-top: 144px;
    max-width: 1134px;
    margin: auto;
    @media #{$mediaMaxWidth929} {
      flex-direction: column;
      padding-top: 0;
    }

  &__centralizer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media #{$mediaMaxWidth929} {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__image-and-backlight {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    flex-basis: 50%;

    @media #{$mediaMaxWidth929} {
      flex-basis: auto;
      width: 100%;
      position: static;
      order: 2;
    }
  }

  &__macbook-backlight {
    position: absolute;
    top: 44%;
    right: 32%;
    transform: translateY(-50%);
    width: 80%;
    height: 100%;
    background: rgba(23, 115, 150, 0.7);
    border-radius: 50%;
    filter: blur(142.4356231689px);
    z-index: -1;

    @media #{$mediaMaxWidth929} {
      position: absolute;
      top: 79%;
      right: 14%;
      transform: translateY(-50%);
      width: 82%;
      height: 35%;
      background: rgba(23, 115, 150, 0.7);
      border-radius: 50%;
      filter: blur(142.4356231689px);
      z-index: -1;
    }
  }

  &__macbook-image {
    display: block;
    margin-bottom: -16px;
    max-width: 861px;
    width: 90%;

    @media #{$mediaMaxWidth929} {
      width: 100%;
      margin: 0px 40px -10px;
    }
  }

  &__text-container {
    z-index: 2;
    position: absolute;
    left: 67%;
    top: 38%;
    transform: translateY(-50%);
    width: 541px;
    background: none;
    text-align: start;

    &-title {
      font-family: $primaryFont;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $maxSecondaryBlue100;
      margin-bottom: 24px;

      @media #{$mediaMaxWidth768} {
        font-size: 24px;
      }
    }

    &-description {
      margin-bottom: 40px;
    }

    @media #{$mediaMaxWidth929} {
      width: 100%;
      padding-inline: 30px;
      position: static;
      transform: none;
      order: 1;
      display: flex;
      flex-direction: column;
      margin: 48px 0px;
    }
    @media #{$mediaMaxWidth768} {
      padding-inline: 0;
      }
  }

  &__final-button {
    display: flex;

    @media #{$mediaMaxWidth929} {
      display: block;
    }
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 19px;
  padding: 1em;
  text-align: center;
  background: linear-gradient(
    93deg,
    rgba($maxSecondaryBlue100, 0.9) -47.37%,
    rgba($maxPrimaryPurple100, 0.9) 107.26%
  );
  position: relative;
  margin: 0 -50vw 0 -50vw;

  @media #{$mediaMaxWidth768} {
    margin: auto;
  }

  &__text {
    padding: 80px 0 80px 0;
    max-width: 573px;

    @media #{$mediaMaxWidth768} {
      padding: 75px 0 75px 0;
    }
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    justify-content: center;

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      align-items: center;
    }


    @media #{$mediaMaxWidth768} {
      img {
        width: 37%;
      }
    }

    @media (max-width: 1200px) {
      img {
        width: 37%;
      }
    }

    @media (max-width: 1100px) {
      img {
        width: 37%;
      }
    }
  }

  &__maximize-text {
    margin-top: 20px;

    @media #{$mediaMaxWidth768} {
      margin-top: 10px;
      padding-inline: 1%;
    }
  }
}

@media (min-width: 300px) and (max-width: 1133px) {
  .final-section{
    margin-inline: 30px;
  }
}

@media #{$mediaMaxWidth768} {
  .final-section__description-text {
    margin-bottom: 20px;
  }

  .final-section__develop-app-button {
    a {
      width: fit-content;
      margin-bottom: 20px;
    }
  }
}

@media (min-width: 1075px) and (max-width: 1134px) {
  .final-section__text-container {
    position: absolute;
    left: 51%;
    width: 48% !important
  }

  .final-section__macbook-image {
    max-width: 70%
  }
}

@media (min-width: 1024px) and (max-width: 1074px) {
  .final-section__text-container {
    position: absolute;
    left: 44%;
  }

  .final-section__macbook-image {
    max-width: 60%
  }
}

@media (min-width: 960px) and (max-width: 1023px) {
  .final-section__text-container {
    position: absolute;
    left: 37%;
  }

  .final-section__macbook-image {
    max-width: 50%
  }
}

@media (min-width: 930px) and (max-width: 959px) {
  .final-section__text-container {
    position: absolute;
    left: 38%;
    top: 29%;
    transform: translateY(-47%);
  }

  .final-section__macbook-image {
    max-width: 50%
  }
}

</style>
