import axios from 'axios'

export const Api = axios.create({
    baseURL: import.meta.env.VITE_APP_APPMAX_API_APPBOX_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
})

export const ApiOAuth = axios.create({
    baseURL: import.meta.env.VITE_APP_APPMAX_API_APPBOX_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('user-token')}`
    },
})