<template>
  <div
    :class="`w-fit md:max-w-4xl background-develop-your-app background-develop-your-app-${mq.current}`"
  >
    <AmArrow
      arrow="left"
      class="w-fit md:max-h-[287px]"
    />
    <div class="title mt-4">
      <span :class="['text-text100']">
        {{ $t('login.developYourAppComponent.title') }}
        <b class="text-primaryPurple120 font-medium">{{ $t('login.developYourAppComponent.emphasis') }}</b>
        {{ $t('login.developYourAppComponent.nextTitle') }}
      </span>
    </div>
    <AmArrow
      arrow="right"
      class="w-fit md:max-h-[287px]"
    />
  </div>
</template>

<script setup>
import { AmArrow } from '@icons'
import { inject } from 'vue'

const mq = inject('mq')
</script>

<style lang="scss" scoped>
.background-develop-your-app {
    text-align: center;

    &-tablet {
        font-size: 40px;
    }

    &-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      overflow: hidden;
      font-size: 20px;

      .title {
          max-width: 73%;
          height: fit-content;
          font-family: $secondaryFont;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }
  }

    &-desktop,
    &-tablet {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;
        margin-top: -25px;
        font-family: $secondaryFont;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .title {
            max-width: 65%;
            margin: 0 auto;
        }
    }
}
</style>
