import _ from 'lodash';

const createAppRoutes = [
    //
]

createAppRoutes.forEach((to, from, routes) => {
    _.forEach(routes, (route) => {
        route.meta = {
            requiresAuth: true
        }
    });
})

export default createAppRoutes
