<template>
  <section class="developer-section-container">
    <div class="developer-section-container__centralizer">
      <div class="developer-section-container__content-container">
        <div class="developer-section-container__developer-area-principal-header">
          <h1 class="developer-section-container__developer-title">
            <span class="developer-section-container__color-emphasis">{{ emphasisStart }}</span>
            <span>{{ $t('home.appStoreFirstTile.nextTitle') }}</span>
            <span class="developer-section-container__color-main">{{ $t('home.appStoreSecondTile.emphasis') }}</span>
            <span class="developer-section-container__color-emphasis">{{ emphasisEnd }}</span>
          </h1>
          <AmTypography
            :text="mq.current === 'desktop' ? $t('home.appstoreDescription') : $t('home.appstoreDescriptionMobile')"
            :fontType="mq.current === 'desktop' ? 'sarabun' : 'roboto'"
            :typographyStyle="mq.current !== 'desktop' ? 'l6' : 'l1'"
            textColor="text20"
            weight="normal"
            class="developer-section-container__appstore-description-text"
          />
          <div class="developer-section-container__button-link-container">
            <AmButton
              variant="maxPrimary"
              type="rounded"
              :label="mq.current === 'desktop' ? $t('home.appStoreCreateAppButton') : $t('home.appStoreCreateAppButtonMobile')"
              size="xxlarge"
              class="developer-section-container__develop-app-button"
              to="/user/create"
            />
            <AmButton
              variant="maxGhost"
              type="rounded"
              :label="$t('home.appStoreConsultDoc')"
              size="xxlarge"
              class="developer-section-container__develop-app-button"
              @click="redirectDoc"
            />
          </div>
        </div>
        <div class="developer-section-container__developer-area-principal-image">
          <img
            class="developer-section-container__programmer-woman"
            src="/src/assets/homeView/programmer_woman.webp"
          />
        </div>
      </div>
    </div>
  </section>
</template>


<script setup>
import { AmTypography, AmButton } from '@appmax_npm/ds-appmax-v3'
import { inject } from 'vue'

const mq = inject('mq')
const env = inject('env')
const emphasisStart = '<'
const emphasisEnd = '/>'
const docURL = `${env.app.documentation}`

const redirectDoc = () => {
  window.open(docURL, '_blank');
}
</script>

<style lang="scss" scoped>
.developer-section-container {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  height: auto;
  overflow: hidden;
  padding-top: 84px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    rgba(102, 79, 194, 0.4) 0%,
    rgba(102, 79, 194, 0.2) 35%,
    $darkModeBackground 100%
  );

  &__centralizer {
    max-width: 1134px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    position: relative;

    @media #{$mediaMinWidth414} {
      width: 100%;
    }
  }

  &__content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  &__developer-area-principal-header {
  flex: 1;

  @media #{$mediaMaxWidth995} {
    padding-right: 142px;
  }

  @media #{$mediaMaxWidth872} {
    padding-right: 0;
  }
}


  &__developer-title {
    font-family: $firaCode;
    font-size: 74.097px;
    font-style: normal;
    color: $white;
    font-weight: 450;
    line-height: normal;
    letter-spacing: -2.223px;
    @media #{$mediaMaxWidth768}, #{$mediaMaxWidth995} {
    font-size: 36.35px; 
    }
  }

  &__color-emphasis {
    color: $maxPrimaryPurple100;
  }

  &__color-main {
    color: $maxSecondaryBlue100;
  }

  &__appstore-description-text {
    margin-top: 24px;
    max-width: 583px;
  }

  &__button-link-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 40px;

    @media #{$mediaMaxWidth768} {
      flex-direction: column;
      align-items: center;
      margin-top: 32px;
      gap: 8px;
    }
  }

  &__develop-app-button {
    @media #{$mediaMaxWidth768} {
      width: -moz-available;
      width: -webkit-fill-available;
    }
  }

  &__programmer-woman {
    max-width: 748px;
    @media #{$mediaMaxWidth1135} {
      width: 300px;
    }

    @media #{$mediaMaxWidth995} {
      width: 200px;
    }

    @media #{$mediaMaxWidth768} {
      display: none;
    }
  }
}
</style>
