<template>
  <AmLoading 
    v-if="loading"
    :isLoading="loading"
    class="m-auto"
  />
  <div 
    v-else
    class="login-view">
    <AmToast
      v-if="showAlert"
      :message="alertMessage"
      :duration="3000"
      margin="mt-28"
      position="top-right"
      closable
    />
    <BackgroundDevelopYourApp :size="mq.current" />
    <div :class="`login-view__form--${mq.current}`">
      <AmInput
        v-model="state.email"
        :validations="rules.email"
        feedbackType="error"
        :label="$t('login.form.email.label')"
        placeholder="example@gmail.com"
        dataTestName="login-form_email"
        size="fullWidth"
        class="login-view__form-input--email"
      />
      <AmInput
        v-model="state.password"
        :validations="rules.password"
        feedbackType="error"
        :label="$t('login.form.password.label')"
        :placeholder="$t('login.form.password.placeholder')"
        dataTestName="login-form_password"
        type="password"
        size="fullWidth"
        class="login-view__form-input--password"
      />

      <ReCaptcha
        v-if="!isLocal && siteKey"
        class="login-view__form-recaptcha"
        :siteKey="siteKey"
        recaptchaId="login-recaptcha"
        @captcha-verified="onRecaptchaChange"
      />

      <AmButton
        class="login-view__form-submit"
        :label="$t('login.form.actions.access')"
        variant="maxPrimary"
        size="xlarge"
        :disabled="!formValid"
        @eventKeyAmButton="submitLogin"
        @click="submitLogin"
      />

      <AmButton
        class="login-view__user-create"
        :label="$t('login.form.actions.userCreate')"
        variant="maxOutlined"
        size="xlarge"
        @click="router.push({ name: 'user-create' })"
      />

      <AmButton
        :label="$t('login.form.actions.forgotPassword')"
        variant="maxGhost"
        :size="mq.current === 'desktop' ? 'medium' : 'xlarge'"
        class="login-view__form-forgot-password"
        to="/forgot-password/recover"
      />

    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  inject,
  onBeforeUnmount,
  reactive,
  watch,
} from 'vue'
import { useNavbarStore } from '@/stores/navbar'
import { AmInput, AmButton, AmToast, AmLoading } from '@appmax_npm/ds-appmax-v3'
import BackgroundDevelopYourApp from '../components/atoms/BackgroundDevelopYourApp/BackgroundDevelopYourApp.vue'
import ReCaptcha from '../components/atoms/ReCaptcha/ReCaptcha.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { required, email, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useAuthStore } from '../stores/auth'
import { useHttpStore } from '../stores/http'

const mq = inject('mq')
const loading = ref(false)
const router = useRouter()
const { t } = useI18n()
const navbarStore = useNavbarStore()
const authStore = useAuthStore()
const httpStore = useHttpStore()

const state = reactive({
  email: '',
  password: '',
  recaptchaToken: ''
})
const isLogged = ref(false)
const showAlert = ref(false)
const alertMessage = ref(null)

const rules = computed(() => ({
  email: {
    required: helpers.withMessage(
      `${t('createAccount.form.email.error.required')}`,
      required
    ),
    email: helpers.withMessage(
      `${t('createAccount.form.email.error.invalid')}`,
      email
    )
  },
  password: {
    required: helpers.withMessage(
      `${t('createAccount.form.password.error.required')}`,
      required
    )
  }
}))

const v$ = useVuelidate(rules, state)
const formValid = ref(false)

const siteKey = computed(() => {
  return import.meta.env.VITE_APP_RECAPTCHA_V2_SITE_KEY || ''
})

const isLocal = computed(() => {
  return import.meta.env.VITE_APP_LOCAL === 'local'
})

const onRecaptchaChange = response => {
  state.recaptchaToken = response
}

const validForm = async () => {
  const validations = {
    email: await v$.value.email.$validate(),
    password: await v$.value.password.$validate()
  }

  return Object.values(validations).every(validation => validation)
}

watch(
  () => state,
  async () => {
    formValid.value = await validForm()
  },
  { deep: true }
)

const submitLogin = async () => {
  loading.value = true
  const isValid = formValid.value

  if (!isValid) {
    loading.value = false
    return
  }

  await httpStore.api.post(`/auth/user/login`, {
    email: state.email,
    password: state.password,
    recaptcha_token:
      import.meta.env.VITE_APP_LOCAL === 'local'
        ? 'testing'
        : state.recaptchaToken
  })
    .then(response => {
      localStorage.setItem('user-token', response.data.data.access_token)
      authStore.setToken(response.data.data.access_token)
      navbarStore.setPrivateMode()
      isLogged.value = true

      authStore.setStatusAuthentication(true)

      router.push({ name: 'first-access' })
    })
    .catch(({response}) => {
      loading.value = false
      showAlert.value = true
      authStore.setStatusAuthentication(true)

      const responseData = response.data.data

      alertMessage.value = {
        alertType: 'warning',
        title: computed(() =>
          t('login.form.alerts.title')
        ),
        description: computed(() =>
          t(`login.form.alerts.${responseData.translate}`)
        )
      }

      setTimeout(() => {
        showAlert.value = false
      }, 3000)
    })
}

onMounted(() => {
  navbarStore.setAuthenticationMode()
})

onBeforeUnmount(() => {
  if (isLogged.value) {
    navbarStore.setPrivateMode()
  } else {
    navbarStore.setPublicMode()
  }
})
</script>

<style lang="scss" scoped>
.login-view {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 126px 0 0 0;

    &__form {
        &--desktop,
        &--tablet {
            display: grid;
            grid-template-rows: auto;
            gap: 24px;
            width: 300px;
            margin: 64px auto;
        }

        &--mobile {
            display: grid;
            grid-template-rows: auto;
            gap: 23px;
            padding: 35px 32px 0 35px;
        }
    }
}
</style>
